body {
  margin: 0;
  font-family: "Weissenhof Grotesk";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Weissenhof Grotesk";
  src: local("Weissenhof Grotesk"), url(./fonts/WeissenhofGrotesk.woff) format("woff");
}


